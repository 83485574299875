<template>
  <div>
    <v-card
      flat
      class="datatable-card-background datatable-card-border rounded mb-6"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="auto"
          >
            <app-branch-select
              v-model="queryParams.branch_id"
              classes="small-input"
              hide-details
            />
          </v-col>

          <v-col cols="auto">
            <v-btn
              depressed
              small
              class="me-6"
              @click="$_print()"
            >
              <v-icon left>
                mdil-printer
              </v-icon>

              {{ $t('Print') }}
            </v-btn>

            <v-btn
              color="primary"
              depressed
              small
              @click="getData()"
            >
              <v-icon left>
                mdil-magnify
              </v-icon>

              {{ $t('Search') }}
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              :label="$t('Search...')"
              dense
              outlined
              hide-details
              class="small-input"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      flat
      class="datatable-card-background datatable-card-border rounded"
    >
      <v-card-text>
        <v-simple-table
          id="print-area"
          class="print-table"
          dense
        >
          <template #default>
            <thead>
              <tr v-if="apiData.total">
                <th
                  colspan="3"
                  class="text-center text-body-2 font-weight-bold"
                >
                  {{ $t('Total Customers') }}: {{ $_format_number(apiData.total.count) }}
                </th>
                <th
                  colspan="2"
                  class="text-center text-body-2 font-weight-bold"
                >
                  {{ $t('Total Amount') }}: {{ $_format_number(apiData.total.total) }}
                </th>
              </tr>
              <tr>
                <th class="text-center">
                  #
                </th>
                <th>{{ $t('Customer Name') }}</th>
                <th>{{ $t('Phone') }}</th>
                <th>{{ $t('Item Name') }}</th>
                <th class="text-center">
                  {{ $t('Monthly Receipt Amount') }}
                </th>
                <th class="text-center">
                  {{ $t('Receipt Date') }}
                </th>
                <th class="d-print-none" />
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(data, index) in filteredData"
                :key="index"
                :class="{
                  'grey': index % 2 == 1,
                  'lighten-3': !$vuetify.theme.dark,
                  'darken-3': $vuetify.theme.dark,
                }"
              >
                <td class="text-center">
                  {{ index + 1 }}
                </td>
                <td class="text-start">
                  {{ data.customer_name }}
                </td>
                <td class="text-start">
                  {{ data.customer_phone1 }}
                </td>
                <td class="text-start">
                  {{ data.item }}
                </td>
                <td class="text-center">
                  {{ $_format_number(data.monthly_receipt_amount) }} $
                </td>
                <td class="text-center">
                  {{ $_format_date(data.next_receipt_date) }}
                </td>
                <td class="py-1 d-print-none">
                  <div class="d-flex align-center">
                    <v-btn
                      color="primary"
                      small
                      depressed
                      text
                      @click="changeNextReceiptDate(data)"
                    >
                      {{ $t('Does not pay this month') }}
                    </v-btn>

                    <v-btn
                      color="indigo"
                      small
                      depressed
                      text
                      @click="changeMonthlyReceiptAMount(data)"
                    >
                      {{ $t('Change monthly receipt amount') }}
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="changeDateDialog"
      persistent
      max-width="500px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6">
          <div class="col-12 pa-0">
            {{ $t('Change next receipt date for') }}
          </div>
          <div class="error--text col-12 pa-0">
            {{ formData.customer_name }}
          </div>
        </v-card-title>

        <validation-observer
          ref="theForm"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(submit)"
            @reset.prevent="reset"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Date')"
                    rules="required"
                  >
                    <app-date-picker
                      v-model="formData.next_receipt_date"
                      :label="`* ${$t('Date')}`"
                      outlined
                      dense
                      no-title
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions class="py-3">
              <app-form-tip />

              <v-spacer />

              <v-btn
                depressed
                outlined
                small
                @click="closeDialog()"
              >
                {{ $t('Cancel') }}
              </v-btn>

              <app-submit-button edit-mode />
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="changeMonthlyReceiptDialog"
      persistent
      max-width="500px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6">
          <div class="col-12 pa-0">
            {{ $t('Change monthly receipt amount') }}
          </div>
          <div class="error--text col-12 pa-0">
            {{ formData.customer_name }}
          </div>
        </v-card-title>

        <validation-observer
          ref="theForm"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(submitChangeReceipt)"
            @reset.prevent="reset"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Amount')"
                    rules="required"
                  >
                    <v-currency-field
                      v-model="formData.monthly_receipt_amount"
                      :label="`* ${$t('Amount')}`"
                      outlined
                      dense
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions class="py-3">
              <app-form-tip />

              <v-spacer />

              <v-btn
                depressed
                outlined
                small
                @click="closeDialog()"
              >
                {{ $t('Cancel') }}
              </v-btn>

              <app-submit-button edit-mode />
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      apiData: {
        data: [],
        totals: {}
      },
      queryParams: {},
      formData: {},
      search: '',
      changeDateDialog: false,
      changeMonthlyReceiptDialog: false,
    }
  },

  computed: {
    filteredData() {
      if (this.search && this.search.length) {
        let keyword = this.search.toLowerCase()

        return this.apiData.data.filter(item => {
          return item.customer_name.toLowerCase().includes(keyword) ||
            item.customer_phone1.toLowerCase().includes(keyword) ||
            item?.item?.toLowerCase().includes(keyword)
        })
      }

      return this.apiData.data
    }
  },

  mounted() {
    this.$_section_title({ title: 'Qist', icon: 'mdil-chart-bar' })
    this.getData()
  },

  methods: {
    getData() {
      axios.post(`report/qist/`, this.queryParams).then(res => {
        this.apiData = res.data
      })
    },

    closeDialog() {
      this.formData = {}
      this.changeDateDialog = false
      this.changeMonthlyReceiptDialog = false
    },

    changeNextReceiptDate(data) {
      this.formData = { ...data }
      this.changeDateDialog = true
    },

    submit() {
      axios.put(`monthly-plan/${this.formData.id}/update-next-receipt-date/`, this.formData).then(() => {
        this.$_notify('Updated successfully')
        this.closeDialog()
        this.getData()
      })
    },

    changeMonthlyReceiptAMount(data) {
      this.formData = { ...data }
      this.changeMonthlyReceiptDialog = true
    },

    submitChangeReceipt() {
      axios.put(`monthly-plan/${this.formData.id}/update-monthly-receipt-amount/`, this.formData).then(() => {
        this.$_notify('Updated successfully')
        this.closeDialog()
        this.getData()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>